import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://milanovic.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          VLADIMIR
          <br />
          MILA
          <br />
          NOVIC
        </a>
        <p>Contact</p>
        <p>Vladimir Milanovic</p>
        <p>email: vlada.milanovic@gmail.com</p>
        <p>This site is owned and operated by Vladimir Milanovic</p>
      </header>
    </div>
  );
}

export default App;
